@import "https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@200;400;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200;400;700&display=swap";
@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css";
body {
  color: #131316;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: "Libre\9 Franklin", sans-serif;
}

.nav-spacer {
  width: 100%;
  height: 1em;
}

@media (width >= 768px) {
  .nav-spacer {
    height: 3em;
  }
}

.header-home {
  background-image: url("banner.2f54c534.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 32.5em;
  position: relative;
}

.header-home > .container {
  gap: 2em;
  display: flex;
}

.header img {
  width: 75px;
  height: 75px;
}

@media (width >= 768px) {
  .header img {
    width: 100px;
    height: 100px;
  }
}

.header h2 {
  color: #fff;
  font-size: 2.7em;
  font-weight: 700;
}

.header h1 {
  color: #f0b643;
  filter: blur(10px);
  font-size: 3.3125em;
  font-style: italic;
  font-weight: 700;
  transition: filter 1s ease-in-out .25s;
}

.header h1.showme {
  filter: blur();
}

.header .summary {
  color: #fff;
  font-size: 1.25em;
  font-style: italic;
}

@media (width >= 768px) {
  .header .summary {
    width: 70%;
  }
}

.sub-pages > .container {
  z-index: 5;
  margin-top: -3em;
  position: relative;
}

#letter {
  background-image: url("letter.8302ca20.png");
}

#cpg {
  background-image: url("cpg.241a1662.png");
  transition-delay: .5s;
}

#aspr {
  background-image: url("aspr.7fd7a6aa.png");
  transition-delay: .25s;
}

.delay-1 {
  transition-delay: .5s !important;
}

.delay-2 {
  transition-delay: 1s !important;
}

.modal-content {
  background-color: #f4ece3;
}

h5.modal-title {
  font-size: 2.5em;
  font-weight: 700;
}

.modal-body p {
  font-size: 1.1em;
  font-style: italic;
}

.sub-page-box {
  background-position: center;
  background-size: cover;
  border: 1px solid #eee;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 150px;
  display: flex;
}

.sub-page-box h3 {
  font-size: 1em;
  font-weight: 700;
}

.sub-page-box p {
  margin-bottom: .25rem;
}

.sub-page-box a {
  color: inherit;
  text-decoration: none;
  transition: font-size 1s, color 1s ease-in-out;
}

.sub-page-box a:visited {
  color: inherit;
  text-decoration: none;
}

.sub-page-box a:hover {
  text-shadow: 2px 2px 4px #fff;
  font-size: 1.1em;
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transition: all 1.5s ease-in-out;
  transform: translateY(4em);
}

@media (width >= 576px) {
  .hidden {
    transform: translateY(20vh);
  }
}

.show {
  opacity: 1;
  filter: blur();
  transform: translateY(0);
}

.hidden.next {
  transition-delay: 1s;
}

.page-summary {
  margin-top: 4em;
  margin-bottom: 4em;
}

.page-summary p {
  color: #31466e;
  font-size: 1.5em;
}

.page-summary p span {
  font-style: italic;
  font-weight: 700;
}

footer {
  color: #fff;
  background-color: #003668;
  padding: 1.5em;
}

footer .footer {
  flex-direction: row;
  justify-content: flex-end;
  gap: 1em;
  display: flex;
}

footer .footer img {
  width: 100%;
  max-width: 17rem;
}

footer .footer .logos {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  display: flex;
}

footer .footer .address {
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
  display: flex;
}

footer .footer .address h1 {
  font-size: 1.25em;
  font-weight: 700;
}

footer .footer .address p {
  text-align: right;
  margin-top: 0;
  margin-bottom: 0;
  font-size: .85em;
  font-style: normal;
  line-height: 1.5;
}

footer ul {
  margin: 0;
  padding-left: 1rem;
  font-size: .85em;
  list-style-type: none;
}

footer > div > div > ul > li {
  margin-bottom: .5em;
}

footer .l1 {
  font-size: 1.2em;
  font-weight: 700;
}

footer a {
  color: #fff;
  text-decoration: none;
}

footer a:visited {
  text-decoration: none;
}

footer .version {
  color: #003c73;
  margin-top: 1rem;
  font-size: 8px;
}

.cpg-header {
  background-image: url("cpg-banner.3cc314b1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 25em;
  position: relative;
}

.cpgx {
  z-index: 5;
  margin-top: -6em;
  font-size: 1em;
  position: relative;
}

.cpgx ul {
  padding-left: 1rem;
}

.cpgx ul .adr {
  list-style-type: none;
}

.cpgx div > ul {
  padding-left: 0;
}

.cpgx h1 {
  font-size: 1.4em;
  font-weight: 700;
}

.cpgx h1 span {
  font-size: small;
}

.cpgx h1.title {
  text-align: center;
  font-size: 2em;
}

.cpgx h2 {
  font-size: 1.15em;
  font-weight: 700;
}

.cpgx a {
  text-decoration: none;
}

.cpgx .purpose {
  background-color: #dfe8ef;
  border-radius: 10px;
  padding: 1.5em;
}

.cpgx .download {
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1em;
  display: flex;
}

.cpgx .download div {
  background-color: #113f62;
  border-radius: 8px;
  padding: 0 .5em 0 1em;
  box-shadow: 4px 4px 8px #888;
}

.cpgx .download div a {
  color: #fff;
  align-items: center;
  gap: 1em;
  font-size: 1.25em;
  font-weight: 400;
  display: flex;
}

.cpgx .download a i {
  margin-right: .5em;
  font-size: 1.5em;
}

.cpgx .detail#essentials .entry {
  background-color: #dfe8ef;
}

.cpgx .detail#enhanced .entry {
  background-color: #fee8b9;
}

.cpgx .detail {
  background-color: #113f62;
  border-radius: 10px;
  margin-bottom: 1em;
  padding: 1.5em;
}

.cpgx .detail .entry {
  border-radius: 8px;
  margin-bottom: 1em;
  padding: 1em;
}

.cpgx .detail .entry:last-child {
  margin-bottom: 0;
}

.cpgx .detail .entry .head {
  justify-content: space-between;
  display: flex;
}

.cpgx .detail .entry .head i {
  font-size: 2em;
}

.cpgx .detail .entry .head a.expand, .cpgx .detail .entry .head a.expand :visited {
  color: #000;
}

.cpgx .detail .entry .body {
  transition: all 3s;
}

.cpgx .detail .entry .body:not(.show) {
  filter: blur(2px);
  display: none;
}

.cpgx .tgtr {
  white-space: nowrap;
}

.btn-all {
  justify-content: flex-end;
  display: flex;
}

button.solid {
  color: #fff;
  background-color: #0000;
  border: none;
  font-size: smaller;
  font-style: italic;
}

button.solid:disabled {
  color: #888;
}

button.solid:focus {
  border: none;
}

.teams .wheel-area {
  border: 3px solid #d2a260;
  border-radius: 10px;
  align-items: center;
  gap: 1em;
  margin-bottom: 2em;
  padding: 2em;
  display: flex;
  box-shadow: 5px 5px 8px #bbb;
}

@media (width <= 1068px) {
  .teams .wheel-area {
    flex-direction: column;
  }

  .teams .wheel-area .detail {
    height: 650px;
    min-width: 400px !important;
  }
}

@media (width >= 1068px) {
  .teams .wheel-area .detail > div {
    justify-content: center;
  }
}

.teams .wheel, .teams .wheel .stage {
  width: 600px;
  height: 600px;
  display: block;
  position: relative;
}

.teams .wheel #icon-x {
  object-fit: contain;
  z-index: 20;
  width: 250px;
  height: 250px;
  position: absolute;
  top: 175px;
  left: 175px;
}

.teams .wheel #canvas {
  z-index: 10;
  background: #fff;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.teams .wheel-area .detail {
  flex-grow: 1;
  min-width: 200px;
  padding-top: 2rem;
  position: relative;
}

.teams .wheel-area .detail > div {
  flex-direction: column;
  align-items: center;
  transition: all 1.5s ease-in-out;
  display: flex;
  position: absolute;
  inset: 0;
}

.teams .wheel-area .detail h2, .teams .wheel-area .detail h3 {
  text-align: center;
}

.teams .wheel-area .detail p {
  text-align: left;
  margin-top: 1em;
}

.teams .wheel-area .detail .hidden-w {
  opacity: 0;
  filter: blur(5px);
  z-index: 0;
  height: 0;
}

.teams .wheel-area .detail .show-w {
  opacity: 1;
  filter: blur();
  z-index: 100;
}

.teams .wheel-area .detail .download {
  background-color: #d2a260;
  border-radius: 8px;
  align-self: flex-end;
  padding: 0 .5em 0 1em;
  box-shadow: 4px 4px 8px #888;
}

.teams .wheel-area .detail .download a {
  color: #fff;
  font-variant: small-caps;
  align-items: center;
  gap: 1em;
  font-size: 1.25em;
  font-weight: 400;
  text-decoration: none;
  display: flex;
}

.teams .wheel-area .detail .download a:visited {
  text-decoration: none;
}

.teams .wheel-area .detail .download a i {
  margin-right: .5em;
  font-size: 1.5em;
}

/*# sourceMappingURL=index.ede89a23.css.map */
