// Variables

$small-container-size: 768px;
$large-container-size: 1068px;

@import	url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@200;400;700&display=swap');
@import	url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200;400;700&display=swap');
@import	url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

body {
    background-color: #ffffff;
    color: #131316;
    font-family: 'Libre	Franklin', sans-serif;
    padding: 0;
    margin: 0;
}


.nav-spacer {
    height: 1em;
    width: 100%;
}

@media (min-width: $small-container-size) {
    .nav-spacer	{
	height:	3em;
    }
}

@mixin parallex-header ($image,	$minHeight) {
    position: relative;
    min-height:	$minHeight;

    background:	{
	attachment: fixed;
	position: center;
	repeat:	no-repeat;
	size: cover;
	image: url("../images/#{$image}");
    }
}

@mixin boldFont	($size)	{
    font: {
	size: $size;
	weight:	700;
    }
}

/* ========================================== */
/*  INDEX Page				      */
/* ========================================== */

.header-home {
    @include parallex-header('banner.png', 32.5em);
  
    > .container {
	display: flex;
	gap: 2em;
    }
}

.header	{
    img	{
	width: 75px;
	height:	75px;

	@media (min-width: $small-container-size) {
	    width: 100px;
	    height: 100px;
	}
    }

    h2 {
	color: #ffffff;
	@include boldFont(2.7em);
    }
	    
	h1 {
	   	@include boldFont(3.3125em);
	   	font-style: italic;
		   color: #f0b643;
	   	filter: blur(10px);
	
	   	transition: filter 1s ease-in-out;
		transition-delay: 250ms;
	}
	   	h1.showme {
	   		filter: blur(0px);
			// font-size: 3.3125em;
	   	}

    .summary {
	color: #ffffff;
	font-size: 1.25em;
	font-style: italic;

	@media (min-width: $small-container-size) {
	    width: 70%;
	}
    }
}

/* Section: Sub	Pages */

.sub-pages {
    > .container {
	position: relative;
	margin-top: -3em;
	z-index: 5;
    }
}

#letter	{
    background-image: url("../images/letter.png");
}

#cpg {
    background-image: url("../images/cpg.png");
    transition-delay: 500ms;
}

#aspr {
    background-image: url("../images/aspr.png");
	transition-delay: 250ms;
}

.delay-1  {
		transition-delay: 500ms !important;
	}

	.delay-2  {
		transition-delay: 1s !important;
	}
.modal-content {
    background-color: #f4ece3;
}

h5.modal-title {
    @include boldFont(2.5em);
}

.modal-body p {
    font: {
	size: 1.1em;
	style: italic;
    }
}

.sub-page-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height:	150px;
    border: 1px	solid #eee;
    border-radius: 10px;
    background-size: cover;
	background-position: center;

    h3 {
	@include boldFont(1em);
    }

    p {
	margin-bottom: 0.25rem;
    }

    a {
	color: inherit;
	text-decoration: none;
	transition: font-size 1s, color	1s ease-in-out;
    
	&:visited {
	    color: inherit;
	    text-decoration: none;
	}

	&:hover	{
	    font-size: 1.1em;
	    text-shadow: 2px 2px 4px white;
	}
    }
}

.hidden	{
    opacity: 0;
    filter: blur(5px);
    transform: translateY(4em);
    transition:	all 1.5s ease-in-out;

    @media (min-width: 576px) {
	transform: translateY(20vh);
    }
}

.show {
    opacity: 1;
    filter: blur(0px);
    transform: translateY(0);
}

.hidden.next {
    transition-delay: 1000ms;
}


/* Section: Page Summary */
/* --------------------- */

.page-summary {
    margin-top:	4em;
    margin-bottom: 4em;

    p {
	font-size: 1.5em;
	color: #31466e;
	span {
	    font: {
		style: italic;
		weight:	700;
	    }
	}
    }
}


/* Footer		 */
/* --------------------- */

footer {
    background-color: rgb(0, 54, 104);
    color: #ffffff;
    padding: 1.5em 1.5em;

    .footer {
	display: flex;
	flex-direction:	row;
	justify-content: flex-end;
	gap: 1em;

	img {
	    max-width: 17rem;
	    width: 100%;
	}
 
	.logos {
	    display: flex;
	    flex-direction: column;
	    align-items: flex-start;
		gap: 1rem;
	}

	.address {
	    display: flex;
	    flex-grow: 1;
	    flex-direction: column;
	    align-items: flex-end;

	    h1 {
		@include boldFont(1.25em);
	     }

	    p {
		font-size: 0.85em;
		font-style: normal;
		line-height: 1.5;
		text-align: right;
		margin:	{
		    bottom: 0;
		    top: 0;
		}
	    }
	}
    }

    ul {
	list-style-type: none;
	margin:	0;
	padding-left: 1rem;
	font-size: .85em;
    }

    >div>div>ul>li {
	margin-bottom: .5em;
    }

    .l1	{
	@include boldFont(1.2em);
    }

    a {
	text-decoration: none;
	color: #ffffff;
	
	&:visited {
	    text-decoration: none;
	}
    }

    .version {
	margin-top: 1rem;
	font-size: 8px;
	color: rgb(0, 60, 115);
    }
}
/* ========================================== */
/*  CPG	Page				      */
/* ========================================== */

.cpg-header {
    @include parallex-header( 'cpg-banner.png',	25em);
}

.cpgx {
    position: relative;
    margin-top:	-6em;
    z-index: 5;

    font-size: 1em;

    ul {
	padding-left: 1rem;
	.adr { list-style-type:	none; }
    }

    div>ul {
	padding-left: 0px;
    }

    h1 {
	font: {
	    size: 1.4em;
	    weight: 700;
	}

	span { font-size: small; }
    }

    h1.title {
	font-size: 2em;
	text-align: center;
    }

    h2 {
	@include boldFont(1.15em);
    }

    a {
	text-decoration: none;
    }

    .purpose {
	padding: 1.5em;
	background-color: #dfe8ef;
	border-radius: 10px;
    }

    .download {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	gap: 1em;

	div {

	    padding: {
		right: .5em;
		left: 1em;
		top: 0;
		bottom:	0;
	    }
	    background-color: #113f62;
	    border-radius: 8px;
	    box-shadow:	4px 4px	8px #888;

	    a {
		color: white;
		font-weight: 400;
		font-size: 1.25em;

		display: flex;
		gap: 1em;
		align-items: center;
	    }
	}

	a i {
	    font-size: 1.5em;
	    margin-right: 0.5em;
	}
    }

    .detail#essentials .entry {	background-color: #dfe8ef; }
    .detail#enhanced .entry { background-color:	#fee8b9; }

    .detail {
	padding: 1.5em;
	background-color: #113f62;
	border-radius: 10px;
	margin-bottom: 1em;

	.entry {
	    padding: 1em;
	    border-radius: 8px;
	    margin-bottom: 1em;

	    &:last-child {
		margin-bottom: 0;
	    }

	    .head {
		display: flex;
		justify-content: space-between;
 
		i { font-size: 2em; }

		a.expand { 
		    color: #000;
		    & :visited { color:	#000; }
		}
	    }

	    .body { 
		transition: all	3s;

		&:not(.show) {
		    display: none;
		    filter: blur(2px);
		}
	    }
	}
    }

    .tgtr {
	white-space: nowrap;
    }
}

/* ========================================== */

.btn-all {
    display: flex;
    justify-content: flex-end;
}

button.solid {
    background-color: transparent;
    color: #fff;
    border: none;
    font: {
	style: italic;
	size: smaller;
    }

    &:disabled { color:	#888; }
    &:focus { border: none; }
}

/* ========================================== */
/*  Wheel				      */
/* ========================================== */

.teams {

    .wheel-area {
		display: flex;
		align-items: center;
		gap: 1em;

		border: 3px solid #D2A260;
		border-radius: 10px;
		padding: 2em;
		box-shadow: 5px 5px 8px #bbb;
		margin-bottom: 2em;

		@media (max-width: $large-container-size) {
			flex-direction: column;

			.detail {
				height: 650px;
				min-width: 400px !important;
			}
		}

		@media (min-width: $large-container-size) {
			.detail>div {
				justify-content: center;
			}
		}
	}

    .wheel {
		display: block;
		width: 600px;
		height:	600px;
		position: relative;
 
		.stage {
			display: block;
			width: 600px;
			height: 600px;
			position: relative;
		}

		#icon-x	{
			position: absolute;
			object-fit:	contain;
			left: 175px;
			top: 175px;
			width: 250px;
			height: 250px;
			z-index: 20;
		}

		#canvas	{
			margin: 0;
			top: 0;
			left: 0;
			position: absolute;
			z-index: 10;
			background:	#fff;
		}
    }

	.wheel-area {
		.detail {
			position: relative;
			flex-grow: 1;
			min-width: 200px;
			padding-top: 2rem;

			> div {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;

				transition:	all 1.5s ease-in-out;

				display: flex;
				flex-direction: column;
				align-items: center;
			}

			h2 { text-align:	center; }
			h3 { text-align:	center;}

			p {
				margin-top:	1em;
				text-align:	left;
			}

			.hidden-w {
				height: 0;
				opacity: 0;
				filter: blur(5px);
				z-index: 0;
			}

			.show-w {
				opacity: 1;
				filter: blur(0px);
				z-index: 100;
			}

			.download {
				padding: {
                    right: .5em;
				    left: 1em;
				    top: 0;
				    bottom: 0;
                }

				background-color: #D2A260;
				border-radius: 8px;
				box-shadow:	4px 4px	8px #888;
				align-self:	flex-end;

				a {
					color: white;
					text-decoration: none;
					font: {
                        weight: 400;
					    size: 1.25em;
					    variant: small-caps;
                    }
                    
					display: flex;
					gap: 1em;
					align-items: center;

					&:visited { text-decoration: none; }
				
					i {
						font-size: 1.5em;
						margin-right: 0.5em;
					}
				}
			}
		}
	}
}